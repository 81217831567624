import React from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';

import Button from '@heureca/shared/components/Button';
import Input from '@heureca/shared/components/Input';
import IFormValues from '@heureca/shared/models/IFormValues.model';
import PreRegistration from '@heureca/shared/models/PreRegistration.model';
import validatorsUtils from '@heureca/shared/utils/validators.utils';
import Steps from '@heureca/shared/components/Steps';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import useSignUp from '@heureca/signUp/hooks/useSignUp.hook';
import routeConstants from '@heureca/signUp/constants/route.constants';
import Layout from '@heureca/shared/components/Layout';
import analyticsUtils from '@heureca/shared/utils/analytics.utils';

import SignUpSectionCard from '../SignUpSectionCard';

import * as styles from './contactStep.module.scss';

const ContactStep = () => {
  const { translate } = useTranslations();
  const { signUp, updateSignUp } = useSignUp(() => navigate(routeConstants.CONFIRM));
  const { value } = signUp.contact;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const onNext = ({ contact }: Partial<PreRegistration>) => {
    analyticsUtils.addEvent({
      event: 'Contact step completed',
    });

    updateSignUp({
      contact: {
        valid: true,
        value: contact,
      },
    });
  };

  return (
    <Layout pageTitle={translate('signUp.title')} isSignUp>
      <SignUpSectionCard
        name="contact-data"
        title={translate('signUp.steps.contact')}
      >
        <Steps currentStep={3} />
        <form className={styles.form} onSubmit={handleSubmit(onNext)}>
          <Input
            label={translate('signUp.email')}
            placeholder="Ex: john@doe.com"
            wrapperClassName={styles.input}
            required
            register={register}
            name="contact.email"
            error={errors.contact?.email}
            validate={validatorsUtils.isEmailValid}
            customErrorMessage={translate('signUp.emailError')}
            value={value?.email}
          />
          <Input
            label={translate('signUp.phoneNumber')}
            placeholder="Ex: 0498/27.22.59"
            wrapperClassName={styles.input}
            required
            register={register}
            name="contact.phone"
            error={errors.contact?.phone}
            customErrorMessage={translate('signUp.phoneNumberError')}
            value={value?.phone}
          />
          <Input
            label={translate('signUp.firstName')}
            placeholder="Ex: John"
            wrapperClassName={styles.input}
            required
            register={register}
            name="contact.managerFirstName"
            error={errors.contact?.managerFirstName}
            customErrorMessage={translate('signUp.firstNameError')}
            value={value?.managerFirstName}
          />
          <Input
            label={translate('signUp.lastName')}
            placeholder="Ex: Doe"
            wrapperClassName={styles.input}
            required
            register={register}
            name="contact.managerLastName"
            error={errors.contact?.managerLastName}
            customErrorMessage={translate('signUp.lastNameError')}
            value={value?.managerLastName}
          />
          <div className={styles.buttonWrapper}>
            <Button
              label={translate('signUp.next')}
              type="submit"
            />
          </div>
        </form>
      </SignUpSectionCard>
    </Layout>
  );
};

export default ContactStep;
